import React, { useState } from "react";
import styles from "./PriceBox.module.css";
import { isEmpty } from "lodash";
import axios from "axios";

export default function PriceBox({ data }) {
  const [loading, setLoading] = useState(false);

  const handlePay = async (amount) => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `https://us-central1-tripsmagic.cloudfunctions.net/goviralSponsor/api/goviral/sponsor/egl/payment_request`,
        data: { amount },
      };
      const response = await axios(config);
      if (response?.data?.status == "success") {
        let url =
          response?.data?.paymentPayload?.data?.instrumentResponse?.redirectInfo
            ?.url;
        window.location.href = url;
      }
      if (response?.data?.status == "fail") {
        // setErrorMessage(response?.data?.msg);
      }
      setLoading(false);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div
      className={`w-full max-w-[600px] rounded-[16px] border-[1px] border-[#4E04C6] p-[16px] shrink-0 flex flex-col cursor-pointer ${styles.priceWrapper}`}
    >
      <div className="w-[100%]  mt-[32px] mb-[20px]">
        <h4 className="mb-[16px]">{data?.heading}</h4>
        <p className={styles.packDescription}>{data?.subTitle}</p>
      </div>
      {!isEmpty(data?.rules) && (
        <div>
          <p className={styles.packDescription}>Featues</p>
          <ul>
            {data?.rules?.map((el) => {
              return <li className={styles.packFeatures}>{el}</li>;
            })}
          </ul>
        </div>
      )}
      {!isEmpty(data?.extraCost) && (
        <div className="mt-[20px]">
          <p className={styles.packDescription}>Extra Costs</p>
          <ul>
            {data?.extraCost?.map((el) => {
              return <li className={styles.packFeatures}>{el}</li>;
            })}
          </ul>
        </div>
      )}
      <div className="flex items-cneter justify-between w-full">
        <h4 className="mt-[16px]">{data?.price}</h4>
        <button
          className="border-2 px-[10px] py-[8px] bg-black text-white rounded-[8px] flex justify-center hover:cursor-pointer disabled:opacity-50"
          onClick={() => handlePay(data?.amount)}
          disabled={loading}
        >
          {loading ? "Loading" : "Pay now"}
        </button>
      </div>
    </div>
  );
}
