import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

export default function PricingDetailPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="w-[100%] max-w-[1440px] px-[3%] mx-auto text-[#101010] pb-[60px] md:pb-[120px] ">
        <div className="w-full border-2 h-[60vh] flex flex-col justify-center items-center gap-[20px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="w-[50px] h-[50px]"
          >
            <mask
              id="mask0_696_339"
              mask-type="luminance"
              maskUnits="userSpaceOnUse"
              x="1"
              y="0"
              width="22"
              height="24"
            >
              <path
                d="M12 2L14.6265 3.916L17.878 3.91L18.8765 7.004L21.5105 8.91L20.5 12L21.5105 15.09L18.8765 16.996L17.878 20.09L14.6265 20.084L12 22L9.3735 20.084L6.122 20.09L5.1235 16.996L2.4895 15.09L3.5 12L2.4895 8.91L5.1235 7.004L6.122 3.91L9.3735 3.916L12 2Z"
                fill="white"
                stroke="white"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.5 12L11 14.5L16 9.5"
                stroke="black"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </mask>
            <g mask="url(#mask0_696_339)">
              <path d="M0 0H24V24H0V0Z" fill="#0FA958" />
            </g>
          </svg>

          <h1 className="subheading2">Transaction Received</h1>
        </div>
      </div>
      <Footer />
    </>
  );
}
