import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import "./InvestorCorner.css"
import img1 from '../../Assets/image8.png'
import img2 from '../../Assets/image9.png'
import arrow from '../../Assets/rightArrow.png'
import plus from '../../Assets/plus.png'
import cross from '../../Assets/cross.png'
import { useState, useEffect } from 'react'
//import fullData from '../../json/investorCorner.json'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import back from '../../Assets/leftArrow.png'

const InvestorCorner = () => {
  let product = window.location.href.split('/')[4]
  
  const navigate = useNavigate()
  const [question, setQuestion] = useState(0)

  const [fullData, setFullData] = useState({});
  useEffect( () => {
    axios
    .get('/json/investorCorner.json')
    .then(response => setFullData(response.data));

    window.scrollTo(0, 0)
  },[]);

  //let productData = fullData && fullData[`${product}`]

  return (
    <>
      <div className='max-w-[1440px] w-[100%] mx-auto'>
        <section className='relative px-[3%]'>
          <img className='text-black hover:cursor-pointer h-[24px] sm:h-[40px] mt-[20px] sm:my-[30px]' src={back}
            onClick={() => navigate(-1)}
          />
          <div className='flex flex-wrap'>
            <p className='heading max-w-[900px]'>{fullData?.heading}</p>
            <div className='flex items-start mx-auto'>
              <div className='w-[150px] h-[100px] relative flex justify-between '>
                <img src={img1} alt="" className=' w-[100px] h-[100px]' />
                <img src={img2} alt="" className=' w-[100px] h-[100px] absolute right-0' />
              </div>
              <p className='mx-[20px] textStyle1 inter'> Join your hands with<br /> collabration ideas similar<br /> text will be added here.</p>
            </div>
          </div>
        </section>
      </div>
      <div className='max-w-[1440px] w-[100%] mx-auto'>
        {fullData[`${product}`]?.map(data => {
          return (
            <>
              {data?.img1 &&
                <img src={data?.img1} alt="" className='w-[100%] min-h-[210px] mt-[30px] object-fit px-[3%]' />
              }
              <section className='px-[3%]'>
                <p className='textStyle2 md:mt-[60px] mt-[20px]'>{data?.subHeading1}</p>
                <div className='md:w-[70%] w-[100%]'>
                  <p className='subheading2 md:mt-[30px] mt-[16px]'>{data?.subDescription}</p>
                </div>
                <div className='flex flex-wrap justify-around items-center mt-[40px] sm:mt-[110px]'>
                  <div className='flex flex-col items-center'>
                    {data?.img2 &&
                      <>
                        <img src={data?.img2} alt="" className='w-[391px] h-[476px] sm:h-[536px] object-contain' />
                        <div className='flex items-center justify-center max-w-[328px] mt-[30px] sm:mt-[20px]'>
                          {data?.logo &&
                            <img src={data?.logo} alt="" className='w-[32px] h-[32px] mr-[10px]' />
                          }
                          <span className='textStyle3  '>{data?.desc}</span>
                        </div>
                      </>
                    }
                  </div>
                  <p className='subheading2  w-[100%] md:w-[50%] mt-[60px] md:mt-0' style={{ color: '#A3A3A3' }}> <span className='text-black'>Our Vision.</span> {data?.vision}</p>
                </div>
              </section>
              {/* The Problem We Want to Solve */}
              <section className='mt-[60px] md:mt-[120px] px-[3%]'>
                <p className='heading2 max-w-[571px]'>{data?.problemHeading}</p>
                <div className='w-[100%] md:w-[70%] md:mt-[30px] mt-[20px]'>
                  <p className='subheading2'>{data?.problemDesc}</p>
                </div>
              </section>
              {/* Solution */}
              <section className='mt-[60px] md:mt-[120px] px-[3%]'>
                <p className='heading2'>Solutions</p>
                <div className='grid md:grid-cols-2 '>
                  {data?.solutions.map(obj => {
                    return (
                      <div className='w-[100%] md:w-[80%] mt-[16px] md:mt-[60px]'>
                        <p className='subheading max-w-[415px]'>{obj?.heading}</p>
                        <p className='textStyle4 inter mt-[16px]'>{obj?.description}</p>
                      </div>
                    )
                  })}
                </div>
              </section>

              <section className=' mt-[120px] px-[3%]'>
                <p className='heading2 max-w-[740px]'>{data?.productHeading}</p>
                {data?.productImg &&
                  <img src={data?.productImg} alt="" className='w-[100%] min-h-[510px] mt-[30px] object-cover object-[75%]' />
                }
                <div className='w-[100%] flex justify-end'>
                  <div className='flex flex-wrap  w-[100%] md:w-[80%] md:gap-3 justify-end'>
                    {data?.products.map(obj => {
                      return (
                        <div className='w-[100%] md:w-[49%] mt-[30px] md:mt-[60px]'>
                          <p className='subheading2' style={{ fontWeight: '600' }}>{obj.heading}</p>
                          <p className='textStyle4 inter mt-[16px]'>{obj.description} </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>

              <section className='mt-[60px] md:mt-[120px] px-[3%]'>
                <p className='heading2'>Traction</p>
                <p className='subheading3 inter mt-[30px] w-[100%] md:w-[80%]'>{data?.tractionDesc}</p>
                <div className='flex flex-wrap items-center mt-[60px]'>
                  {data?.tractionImg &&
                    <img src={data?.tractionImg} alt="" className='md:w-[60%] w-[100%]' />
                  }
                  <p className='textStyle5 md:ml-[30px] mx-auto mt-[20px] md:mt-0 md:max-w-[224px]'>{data?.tractionText}</p>
                </div>
              </section>

              <section className='mt-[60px] md:mt-[120px] px-[3%]'>
                <p className='heading2 '>{data?.marketingHeading}</p>
                <p className='subheading3 inter mt-[30px] w-[100%] md:w-[75%]'>{data?.marketingDesc}</p>
                <div className='separator mt-[40px]'></div>
                <div className='flex flex-wrap'>
                  {data?.strategies.map(obj => {
                    return (
                      <div className='w-[100%] md:w-[50%] mt-[30px] md:mt-[56px]'>
                        <p className='font-[500] text-[17px] leading-[26px] md:text-[26px] md:leading-[34px]'>{obj.heading}</p>
                        <p className='textStyle4 inter w-[100%] md:w-[80%] mt-[16px] md:mt-[6px]'>{obj.description}</p>
                      </div>
                    )
                  })}
                </div>
              </section>

              <section className='mt-[60px] md:mt-[120px] px-[3%]'>
                <p className='heading2'>{data?.modelHeading}</p>
                <p className='subheading3 inter mt-[30px] w-[100%] md:w-[75%]'>{data?.modelDesc}</p>
                <div className='grid grid-cols-1 sm:grid-cols-2 justify-between mt-[30px] gap-[20px]'>
                  {data?.modelImg.map(obj => {
                    return (
                      <>
                        {obj?.img &&
                          <img src={obj?.img} alt="" className='' />
                        }
                      </>
                    )
                  })}
                </div>
              </section>

              <section className='mt-[60px] md:mt-[120px] px-[3%]'>
                <div className='w-[100%] flex flex-col items-center '>
                  <p className='heading2 text-center max-w-[652px]'>{data?.inviteText}</p>
                  <div className='border1 mt-[60px] hover:cursor-pointer select-none'
                    onClick={() => window.open(`https://wa.me/+919567183183`)}
                  >
                    Contact Button
                    <img src={arrow} alt="" className='h-[40px] w-[40px]' />
                  </div>
                </div>
              </section>
            </>
          )
        })}
      </div>

      {fullData[`${product}`]?.map(data => {
        return (
          <>
            <section className='mt-[60px] md:mt-[120px] text-white  '>
              <div className='w-[100%] bg-black'>
                <div className='w-[100%] max-w-[1440px] mx-auto flex flex-col items-center px-[5%] md:px-[15%] py-[30px] '>
                  <p className='heading3 md:mb-[60px] mb-[30px]'>Questions? Answers.</p>
                  {data?.faq.map(obj => {
                    return (
                      <div className='w-[100%]' key={obj.no}>
                        <div className='w-[100%] flex justify-between'>
                          <p className='subheading5'>{obj.question}</p>
                          <img src={question == obj.no ? cross : plus} alt="" className='h-[24px] w-[24px] hover:cursor-pointer' onClick={() => question === obj.no ? setQuestion("0") : setQuestion(obj.no)} />
                        </div>
                        {question === obj.no && <p className='opacity-60 text-[16px] sm:text-[20px] my-[16px] md:my-[30px]'>{obj.answer}</p>}
                        <div className='separator opacity-60 my-[12px] md:my-[20px]'></div>
                      </div>
                    )
                  })}

                </div>
                <div className='w-[100%] bg-black flex justify-center py-[6px] '>
                  <p className='text-white opacity-60 text-[12px]'>© 2021, EGuidance Labs</p>
                </div>
              </div>
            </section>
          </>
        )
      })}
    </>
  )
}

export default InvestorCorner
