import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const PaymentThirdParty = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const transactUrl = searchParams?.get("gatewayresp");

  useEffect(() => {
    if (transactUrl) {
      window.location.href = decodeURIComponent(transactUrl);
    }
  }, []);

  return (
    <div>
      <h1>Payment Processing... </h1>
    </div>
  );
};
export default PaymentThirdParty;
