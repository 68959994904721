import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import PriceBox from "../../Components/PriceBox/PriceBox";

export default function PricingPage() {
  const [priceDetails, setPriceDetails] = useState([]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadPrice();
  }, []);

  const loadPrice = async () => {
    try {
      const data = await axios.get(`/json/packDetails.json`);
      setPriceDetails(data?.data?.pack);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  
  return (
    <>
      <Navbar />
      <div className="w-[100%] max-w-[1440px] px-[3%] mx-auto text-[#101010] pb-[60px] md:pb-[120px] ">
        <div className="flex gap-[10px] flex-wrap overflow-auto">
          {priceDetails?.map((obj) => (
            <PriceBox data={obj} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
