import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Navbar from '../../Components/Navbar/Navbar'
import { useState, useEffect } from 'react'
import axios from 'axios';

//import data from '../json/privacy.json'

const Privacy = () => {
  const [data, setData] = useState([]);
  useEffect( () => {
      axios
      .get('json/privacy.json')
      .then(response => setData(response.data));

      window.scrollTo(0,0)
  },[]);
  
  return (
    <>
      <Navbar />
      <div className='w-[100%] max-w-[1440px] px-[5%] mx-auto flex flex-col text-[17px]  pt-[30px] pb-[50px]'>
        {data.privacyPolicy?.map((obj) => {

          return (
            <>
              {/* <span className='pb-[10px] text-{}' >{obj.heading}</span> */}
              <span className={`pb-[10px] font-[600]`}
                style={{fontSize : obj?.size, color: obj?.color}}
              >{obj.heading}</span>
              <span className={`pb-[10px] pl-[2%] text-[${obj.color}] text-[${obj.size}]`}>{obj.paragraph}</span>
            </>
          )
        })}
      </div>
      <Footer />
    </>
  )
}

export default Privacy
