import React from 'react'
import AboutIntro from '../../Components/About/AboutIntro'
import AboutUs from '../../Components/About/AboutUs'
import Footer from '../../Components/Footer/Footer'
import Navbar from '../../Components/Navbar/Navbar'
// import data from '../../json/about.json'
import { useState, useEffect } from 'react'
import axios from 'axios';


const About = () => {
  const [data, setData] = useState({});
  useEffect( () => {
      axios
      .get('json/about.json')
      .then(response => {
        setData(response.data)
        console.log(response.data)
    });

      window.scrollTo(0,0)

  },[]);

  return (
    
    <>
      <Navbar />
      <AboutIntro data={data}/>
      <AboutUs data={data}/>
      <Footer/>
    </>
  )
}

export default About
