import React, {useEffect} from 'react'
import Footer from '../../Components/Footer/Footer'
import FundingIntro from '../../Components/Funding/FundingIntro'
import Navbar from '../../Components/Navbar/Navbar'

const Funding = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
      <Navbar />
      <FundingIntro />
      <Footer />
    </>
  )
}

export default Funding
