import React from 'react'
import logo from '../../Assets/egLabsLogo.png'
import { useState, useEffect } from 'react'
import axios from 'axios';

const CoreCapabilities = () => {

  const [teckStack, setTeckStack] = useState([]);
  useEffect( () => {
      axios
      .get('json/coreCapabilities.json')
      .then(response => setTeckStack(response.data));
  },[]);
  
  return (
    <>
      <div className='w-[100%] max-w-[1440px] px-[3%] mx-auto text-[#101010] pb-[60px] md:pb-[120px] '>
        <img src={logo} alt="eg labs" className='h-[45px] w-[72px]' />
        <div className='max-w-[840px] mt-[30px]'>
          <span className='font-[600] text-[21px] leading-[130%] md:text-[36px] '>We believe transparency, clear communication and managing client expectations is a great formula to underpin every successful project.</span>
        </div>
        <div className='font-[600] text-[30px] leading-[120%] md:text-[36px] mt-[30px] md:mt-[40px]'>Our core capabilities</div>
        <div className='grid grid-cols-1 md:grid-cols-2 mt-[30px] gap-[30px] md:gap-[60px]'>

          {teckStack.capabilities?.map((obj, index) => {
            return (
              <>
                <div classname='pt-[30px]' key={index}>
                  <p className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>{obj.name}</p>
                  <p className='inter font-[400] text-[16px] leading-[19px] md:leading-[24px] opacity-60 mt-[16px]'>{obj.desc}</p>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
} 

export default CoreCapabilities
