import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Navbar from '../../Components/Navbar/Navbar'
//import client from '../../json/client.json'
import { useState, useEffect } from 'react'
import axios from 'axios';

const Client = () => {
  const [client, setClient] = useState([]);

  useEffect(() => {

    axios
    .get('json/client.json')
    .then(response => setClient(response.data));

    window.scrollTo(0,0)
  }, [])
  return (
    <>
      <Navbar />
      <div className='px-[3%] w-[100%] max-w-[1440px] mx-auto text-[#101010] pb-[100px]'>
        <div className='max-w-[722px]'>
          <p className='font-[600] text-[36px] leading-[130%] mt-[30px] md:text-[64px] md:leading-[120%] md:mt-[60px]'>{client.heading}</p>
          <p className='font-[500] text-[17px] leading-[26px] mt-[20px] tracking-[0.02em] md:mt-[30px] inter'>{client.description}</p>
        </div>
        <p className='w-[300px] font-[600] text-[21px] leading-[130%] mt-[60px] md:text-[36px] md:leading-[120%] md:mt-[120px] md:w-[440px]'>{client.subheading}</p>
        <div className='grid grid-cols-2 md:grid-cols-4 gap-[8px]'>
          {client.clients?.map(obj => {
            return (
              <>
                <div className='mt-[30px] md:mt-[60px] '>
                  <img src={obj.logo} alt="" className='bg-[#EEEEEE]' />
                  <p className='font-[600] text-[16px] leading-[120%] mt-[12px] md:mt-[16px] '>{obj.name}</p>
                  <p className='font-[400] text-[12px] leading-[21px] mt-[6px] md:mt-[8px] opacity-60 '>{obj.url}</p>
                </div>
              </>
            )
          })}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Client
