import React from "react";
import "./Footer.css";
import button from "../../Assets/forward-button.svg";
import buttonBlack from "../../Assets/forward-button-black.svg";
import logo from "../../Assets/egLabsLogoWhite.svg";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-[100%]mx-auto bg-black md:pt-[120px] pt-[60px] text-white">
        <div className=" max-w-[1440px] mx-auto">
          {/* <div className='px-[3%]'>
            <p className='font-[600] text-[10px] leading-[15px] tracking-[0.04em] md:text-[12px] md:leading-[18px] hover:cursor-pointer'>SIGN UP TO OUR NEWSLETTER:</p>
            <div className='md:mt-[60px] md:w-[50%] mt-[30px]'>
              <div className=' flex justify-between items-center'>
                <input type="text" className='bg-inherit md:h-[77px] md:w-[90%] md:text-[64px] sm:text-[44px] text-[32px] w-[80%] outline-none' placeholder='your@email.com' />
                <div className='md:w-[50px] md:h-[50px] w-[36px] h-[36px] rounded-full footer-button-bg bg-white flex items-center justify-center hover:cursor-pointer'>
                  <img src={button} alt="" />
                </div>
              </div>
              <div className='w-[100%] h-[1px] bg-[#D9D9D9] opacity-40 mt-[6px]'></div>
            </div>
          </div> */}
          {/* <div className='w-[100%] h-[1px] bg-[#D9D9D9] opacity-20 mt-[60px] md:mt-[120px]'></div> */}
          <div className="px-[3%] flex flex-wrap md:pt-[120px] pt-[60px] md:pb-[60px] md:h-[540px] gap-[60px] sm:gap-0">
            <div className="md:w-[50%] min-w-[300px] flex flex-col justify-between">
              <img
                src={logo}
                alt="eg labs"
                className="h-[45px] w-[72px] hover:cursor-pointer"
                onClick={() => navigate("/")}
              />
              <div className="hidden sm:block">
                <div
                  className="border2 md:mt-[60px] mt-[30px] flex justify-between items-center pr-[2%] pl-[5%] hover:cursor-pointer select-none"
                  onClick={() => window.open(`https://wa.me/+919567183183`)}
                >
                  <p>Get in touch</p>
                  <div className="">
                    <div className="w-[40px] h-[40px] rounded-full bg-white flex items-center justify-center">
                      <img src={buttonBlack} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[100%] sm:w-[50%] grid md:grid-cols-2 grid-cols-1">
              <div className="flex flex-col justify-between h-[100%]">
                <div>
                  <p className="font-[500] text-[12px] text-white opacity-40 leading-[18px]">
                    About
                  </p>
                  <div className="font-[500] text-[16px] leading-[30px] text-white md:mt-[12px]">
                    <p>
                      <span
                        className="hover:cursor-pointer"
                        onClick={() => navigate("/about")}
                      >
                        {" "}
                        About
                      </span>
                      <br />
                      <span
                        className="hover:cursor-pointer"
                        onClick={() => navigate("/clients")}
                      >
                        Clients
                      </span>
                      <br />
                      <span
                        className="hover:cursor-pointer"
                        onClick={() => navigate("/investorscorner")}
                      >
                        Investors Corner
                      </span>
                      <br />
                      <span
                        className="hover:cursor-pointer"
                        onClick={() => navigate("/researchAndDevelopment")}
                      >
                        Research & Development
                      </span>
                    </p>
                  </div>
                </div>
                <p
                  className="font-[500] text-[12px] text-white opacity-40 leading-[18px] hidden sm:block underline hover:cursor-pointer"
                  onClick={() => navigate("/privacypolicy")}
                >
                  Privacy Policy
                </p>
                <p
                  className="font-[500] text-[12px] text-white opacity-40 pt-[2px] leading-[18px] hidden sm:block underline hover:cursor-pointer"
                  onClick={() => navigate("/tandc")}
                >
                  Terms & Condition
                </p>
                <p
                  className="font-[500] text-[12px] text-white opacity-40 pt-[2px] leading-[18px] hidden sm:block underline hover:cursor-pointer"
                  onClick={() => navigate("/shipping")}
                >
                  Shipping & Delivery Policy
                </p>
                <p
                  className="font-[500] text-[12px] text-white opacity-40 pt-[2px] leading-[18px] hidden sm:block underline hover:cursor-pointer"
                  onClick={() => navigate("/refund")}
                >
                  Cancellation & Refund Policy
                </p>

                <div></div>
              </div>
              <div className="flex flex-col justify-between h-[100%] mt-[30px] sm:mt-0">
                <div>
                  <p className="font-[500] text-[12px] text-white opacity-40 leading-[18px]">
                    Connect
                  </p>
                  <div className="font-[500] text-[16px] leading-[30px] text-white md:mt-[12px]">
                    <p>
                      1st Floor, KC tower <br /> Near Oberon Mall, Edappally,
                      Kochi
                      <br /> Kerala - 682030, India <br />
                    </p>
                    <p
                      className="mt-[30px] hover:cursor-pointer"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/eguidancelabs/"
                        )
                      }
                    >
                      LinkedIn
                    </p>
                  </div>
                </div>
                <p className="font-[500] text-[12px] text-white opacity-40 leading-[18px] hidden sm:block">
                  © 2023 EGuidance Labs
                </p>
                <div></div>
              </div>
              <div className="sm:hidden block">
                <div
                  className="border2 md:mt-[60px] mt-[30px] flex justify-between items-center pr-[2%] pl-[5%]  hover:cursor-pointer"
                  onClick={() => window.open(`https://wa.me/+919567183183`)}
                >
                  <p className="font-[600] text-[14px] sm:text-[20px] flex select-none">
                    Get in touch
                  </p>
                  <div className="w-[40px] h-[40px] rounded-full bg-white flex items-center justify-center">
                    <img src={buttonBlack} alt="" />
                  </div>
                </div>
              </div>
              <div className=" sm:hidden block ">
                <div className="flex flex-col gap-[10px] w-[100%] my-[30px]">
                  <p
                    className="font-[500] text-[12px] text-white opacity-40 leading-[18px] underline hover:cursor-pointer"
                    onClick={() => navigate("/privacypolicy")}
                  >
                    Privacy Policy
                  </p>
                  <p
                    className="font-[500] text-[12px] text-white opacity-40 leading-[18px] underline hover:cursor-pointer"
                    onClick={() => navigate("/tandc")}
                  >
                    Terms & Conditions
                  </p>{" "}
                  <p
                    className="font-[500] text-[12px] text-white opacity-40 leading-[18px] underline hover:cursor-pointer"
                    onClick={() => navigate("/shipping")}
                  >
                    Shipping & Delivery Policy
                  </p>{" "}
                  <p
                    className="font-[500] text-[12px] text-white opacity-40 leading-[18px] underline hover:cursor-pointer"
                    onClick={() => navigate("/refund")}
                  >
                    Cancellation & Refund Policy
                  </p>
                </div>
              </div>
              <div className=" sm:hidden block ">
                <div className="flex w-[100%] justify-between my-[30px]">
                  <div />
                  <p className="font-[500] text-[12px] text-white opacity-40 leading-[18px]">
                    © 2023 EGuidance Labs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
