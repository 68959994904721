import React from 'react'
import styles from '../../Pages/About/About.module.css'

const AboutIntro = ({ data }) => {
  return (
    <>
      <div className='pb-[100px]'>
        <div className='w-[100%] px-[3%] max-w-[1440px] mx-auto text-[#101010]'>
          <p className='md:text-[64px] text-[32px] font-[600] leading-[120%] '>{data.heading}</p>
          <div className='font-[500] text-[17px] leading-[25.5px] md:text-[26px] md:leading-[34px] max-w-[959px] w-[100%] mt-[20px] md:mt-[30px] inter' dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </div>
        <img src={data.groupImg} alt="" className='max-w-[1440px] w-[100%] mt-[30px] md:mt-[60px] md:px-[3%] mx-auto' />
        <div className='w-[100%] px-[3%] max-w-[1440px] mx-auto text-[#101010] mt-[0px] md:mt-[60px] pb-[60px] md:pb-[20px]'>
          <div className='grid md:grid-cols-2'>
            {data.principles?.map(obj => {
              return (
                <div className='mt-[30px] md:mt-[60px]'>
                  <p className='font-[600] text-[21px] md:text-[36px] leading-[31.5px] md:leading-[44px]'>{obj.heading}</p>
                  <p className='font-[500] text-[17px] md:text-[26px] leading-[22px] md:leading-[34px] mt-[6px] md:mt-[16px] max-w-[525px]'>{obj.subHeading}</p>
                  <p className='font-[400] text-[16px] md:text-[18px] leading-[24px] opacity-60 mt-[12px] md:mt-[16px] max-w-[525px] inter'>{obj.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutIntro
