import React, {useEffect} from 'react'
import Footer from '../../Components/Footer/Footer'
import Navbar from '../../Components/Navbar/Navbar'
import CoreCapabilities from '../../Components/RND/CoreCapabilities'
import TechStack from '../../Components/RND/TechStack'

const RandD = () => {
  useEffect(() => {
    window.scrollTo(0,0)

  }, [])
  return (
    <div>
      <Navbar/>
      <TechStack/>
      <CoreCapabilities/>
      <Footer/>
    </div>
  )
}

export default RandD
