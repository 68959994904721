import React from "react";
import { useNavigate } from "react-router-dom";
import arrow from "../../Assets/arrow-right.svg";
import { useState, useEffect } from "react";
import axios from "axios";

const FundingIntro = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get("json/funding.json").then((response) => setData(response.data));
  }, []);

  return (
    <>
      <div className="w-[100%] max-w-[1440px] px-[3%] mx-auto pb-[120px]">
        <p className="font-[600] text-[32px] sm:text-[64px] leading-[120%] sm:leading-[135%] mt-[60px] sm:mt-[120px] max-w-[590px]">
          Products Inviting Funding.
        </p>
        <p className="font-[500] text-[17px] sm:text-[26px] leading-[130%] sm:leading-[120%] mt-[20px] max-w-[504px]">
          from a space for talent showcase to a ──────{" "}
          <span className="font-[400] italic"> powerful business tool!</span>
        </p>
        <p className="font-[500] text-[17px] sm:text-[26px] leading-[135%] sm:leading-[34px] mt-[30px] sm:mt-[60px] max-w-[959px]">
          As a company we are looking at growing and becoming one of the major
          multinational IT Company in many countries across the globe. We see
          that there is a huge potential and opportunities for the kind of
          services and products which we offer. The company has a global vision
          for growth.
        </p>
      </div>
      {data.product?.map((obj) => {
        return (
          <>
            <div
              className="py-[80px] sm:py-[120px]"
              style={{
                background: `linear-gradient(306.09deg, ${obj?.bgGradColor1} 0%, ${obj?.bgGradColor2} 22.4%, ${obj?.bgGradColor3} 65.16%)`,
              }}
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 w-[100%] max-w-[1440px] px-[3%] mx-auto">
                <div className="block sm:hidden mb-[30px]">
                  <img src={obj?.img} alt="" />
                </div>
                <div className={``} style={{ color: obj?.textColor }}>
                  <p className="font-[600] text-[26px] sm:text-[50px] leading-[122%]">
                    {obj?.name}
                  </p>
                  <p className="font-[400] text-[16px] leading-[24px] mt-[20px] max-w-[415px] opacity-60">
                    {obj?.desc}
                  </p>
                  <p className="font-[500] text-[17px] sm:text-[20px] leading-[24px] mt-[20px] sm:mt-[30px]">
                    Production url: {obj?.url}
                  </p>
                  <div className="flex gap-[10px] mt-[10px]">
                    <button
                      className="border-2 px-[10px] py-[8px] rounded-[8px]  flex justify-center hover:cursor-pointer"
                      style={{ borderColor: obj?.textColor }}
                      onClick={() => window.open("https://" + obj?.url)}
                    >
                      View Website
                    </button>
                    {/* <button
                      className="border-2 px-[10px] py-[8px] rounded-[8px]  flex justify-center hover:cursor-pointer"
                      style={{ borderColor: obj?.textColor }}
                      onClick={() => navigate("/pricing")}
                    >
                      Buy this Product
                    </button> */}
                  </div>
                  <p className="font-[500] text-[16px] leading-[24px] mt-[20px] sm:mt-[30px]">
                    Production stage:{obj?.productStage}
                  </p>
                  <p className="font-[500] text-[16px] leading-[24px] mt-[20px] sm:mt-[30px]">
                    Mobile app:{obj?.app}
                  </p>
                  <p className="font-[600] text-[22px] sm:text-[26px] leading-[24px] mt-[30px]">
                    Investments so far: {obj?.investment}
                  </p>
                  <p className="font-[500] text-[16px] leading-[24px] mt-[12px] sm:mt-[16px]">
                    Funding stage: {obj?.fundingStage}
                  </p>
                  <p className="font-[600] text-[20px] sm:text-[26px] leading-[24px] mt-[30px]">
                    ROI - Minimum Expected Revenue
                  </p>
                  <p className="font-[500] text-[16px] leading-[24px] mt-[12px] sm:mt-[16px]">
                    Year 1: {obj?.year1}
                  </p>
                  <p className="font-[500] text-[16px] leading-[24px]">
                    Year 2: {obj?.year2}
                  </p>
                  <p className="font-[500] text-[16px] leading-[24px]">
                    Year 3: {obj?.year3}
                  </p>
                  <div
                    className="h-[62px] max-w-[378px] w-[100%] mt-[30px] flex items-center justify-center font-[600] text-[17px] sm:text-[20px] leading-[24px] rounded-[10px] text-white hover:cursor-pointer select-none"
                    style={{ background: obj?.buttonColor }}
                    onClick={() => navigate(obj?.product)}
                  >
                    Why do we need investment{" "}
                    <img
                      src={arrow}
                      alt=""
                      className="w-[24px] sm:w-[30px] ml-[16px]"
                    />
                  </div>
                </div>
                <div className=" hidden sm:block">
                  <div className="flex h-[100%] items-center justify-center">
                    <img src={obj?.img} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default FundingIntro;
