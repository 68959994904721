import React from 'react'

import bullet from '../../Assets/oval-bullet.svg'
import devices from '../../Assets/devices.png'

const TechStack = () => {
  return (
    <>
      <div className='w-[100%] max-w-[1440px] px-[3%] mx-auto text-[#101010] pb-[60px] md:pb-[120px] '>
        <div className='max-w-[520px]'>
          <span className='font-[600] text-[30px] leading-[120%] md:text-[36px] '>Tech mentor to fire up your entrepreneurial journey</span>
        </div>
        <p className='max-w-[682px] text-[17px] font-[500] leading-[21px] mt-[20px] tracking-[0.02em] md:mt-[30px] inter'>With our experienced and trusted tech advisors, EG Labs assist you to fire up your entrepreneurial journey. Resilient technology solutions and methodologies of us help you in building the entire IT set-up and to keep the continuity. Collaborate to save your time, optimize your costs and propel your start up idea seamlessly.</p>
        <div className='font-[600] text-[30px] leading-[120%] md:text-[36px] mt-[30px] md:mt-[40px]'>Our Tech Stack</div>
        <div className='grid grid-cols-1 md:grid-cols-2 mt-[30px] gap-[30px] md:gap-[60px]'>
          <div className='m-auto md:hidden mt-[30px]'>
            <img src={devices} alt="" className='' />
          </div>
          <div classname='pt-[30px]'>
            <div className='flex'>
              <div className='relative w-[31px] h-[22px] mr-[8px] md:mr-[10px] md:w-[61px] md:h-[44px] flex items-center justify-center'>
                <img src={bullet} className={`absolute`} />
                <span className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>1</span>
              </div>
              <p className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>Flutter</p>
            </div>
            <p className='inter font-[400] text-[16px] leading-[19px] md:leading-[24px] opacity-60 mt-[16px]'>Craving for beautiful mobile apps that have beautiful designs with smooth animations and great performance? Eguidance Labs will help you build such mobile apps with the help of Flutter in a short time. These mobile apps can be featured on the App store as well as Google Playstore.</p>
          </div>
          <div className='m-auto row-span-3 hidden md:block'>
            <img src={devices} alt="" className='' />
            <p className='coveredby font-[400] text-[26px] leading-[34px] text-[#1d1d1f] flex justify-center mt-[30px]'>Short text will be added here</p>
          </div>
          <div classname='pt-[30px]'>
            <div className='flex'>
              <div className='relative w-[31px] h-[22px] mr-[8px] md:mr-[10px] md:w-[61px] md:h-[44px] flex items-center justify-center'>
                <img src={bullet} className={`absolute`} />
                <span className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>2</span>
              </div>
              <p className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>React</p>
            </div>
            <p className='inter font-[400] text-[16px] leading-[19px] md:leading-[24px] opacity-60 mt-[16px]'>Looking for someone who can develop dynamic and interactive user interfaces? Eguidance Labs can develop stunning user interfaces for all types of applications - web apps, mobile apps, enterprise grade apps and much more.</p>
          </div>
          <div classname='pt-[30px]'>
            <div className='flex'>
              <div className='relative w-[31px] h-[22px] mr-[8px] md:mr-[10px] md:w-[61px] md:h-[44px] flex items-center justify-center'>
                <img src={bullet} className={`absolute`} />
                <span className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>3</span>
              </div>
              <p className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>Node.Js</p>
            </div>
            <p className='inter font-[400] text-[16px] leading-[19px] md:leading-[24px] opacity-60 mt-[16px]'>Did you know that Linkedin, Amazon and Netflix are using Nodejs for frontend and backend development? Well, now you know! Eguidance Labs does a lot of frontend and backend development using Nodejs across platforms like Linux, Windows and Mac. It is the technology choice for many websites.</p>
          </div>
          <div classname='pt-[30px]'>
            <div className='flex'>
              <div className='relative w-[31px] h-[22px] mr-[8px] md:mr-[10px] md:w-[61px] md:h-[44px] flex items-center justify-center'>
                <img src={bullet} className={`absolute`} />
                <span className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>4</span>
              </div>
              <p className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>Java</p>
            </div>
            <p className='inter font-[400] text-[16px] leading-[19px] md:leading-[24px] opacity-60 mt-[16px]'>Why does everyone love Java? Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible. It is a general-purpose programming language intended to let programmers write once, run anywhere (WORA), meaning that compiled Java code can run on all platforms that support Java without the need for recompilation. Cool isn’t it? Hence, Eguidance Labs uses Java for its products.</p>
          </div>
          <div classname='pt-[30px]'>
            <div className='flex'>
              <div className='relative w-[31px] h-[22px] mr-[8px] md:mr-[10px] md:w-[61px] md:h-[44px] flex items-center justify-center'>
                <img src={bullet} className={`absolute`} />
                <span className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>5</span>
              </div>
              <p className='font-[600] text-[17px] leading-[26px] md:text-[26px] md:leading-[44px]'>Python</p>
            </div>
            <p className='inter font-[400] text-[16px] leading-[19px] md:leading-[24px] opacity-60 mt-[16px]'>The programmers nowadays love Python, a language known for scripting or glue language and the increased productivity it provides. It attracts the developer world with its high-level built-in data structure and dynamic binding and dynamic typing capabilities. This makes it simple, cost-effective and easy to build applications and combine existing components.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default TechStack
