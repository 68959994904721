import axios from "axios";
import React, { useEffect, useState } from "react";
//import PageLoading from "../Components/PageLoading/PageLoading";
import Footer from '../../Components/Footer/Footer'
import Navbar from "../../Components/Navbar/Navbar";
function TandC() {
    let count = 0;
    const [data, setData] = useState([]);
    useEffect( () => {
        axios
        .get('json/tandc.json')
        .then(response => setData(response.data));
  
        window.scrollTo(0,0)
    },[]);
    
    return (
        <>
        <Navbar/>

        <div className="w-full h-full">
            <div className="min-h-[80vh]">
                <div className="p-[40px]">
                        <div className="w-full h-full text-[#000]">
                            <span className="font-semibold text-[24px] lg:text-[40px]">
                                {data?.heading}
                            </span>
                            <div className="flex flex-col pt-[30px]">
                                <span className="pb-[10px] font-bold text-[18px] lg:text-[24px]">{data?.question}</span>
                                <div className="flex flex-col text-[16px] lg:text-[21px]">
                                    {data &&
                                        data.termsAndConditions &&
                                        data.termsAndConditions.map((obj) => {
                                            count++;
                                            return (
                                                <div className="flex">
                                                    <span>{count}.</span>
                                                    <span className="pb-[10px] pl-2">{obj.tandc}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}

export default TandC;
