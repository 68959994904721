import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Privacy from "./Pages/Policy/Privacy";
import About from "./Pages/About/About";
import Client from "./Pages/Client/Client";
import Funding from "./Pages/Funding/Funding";
import InvestorCorner from "./Pages/InvestorCorner/InvestorCorner";
import LandingPage from "./Pages/LandingPage/LandingPage";
import RandD from "./Pages/RandD/RandD";
import Refund from "./Pages/Policy/Refund";
import Shipping from "./Pages/Policy/Shipping";
import TandC from "./Pages/Policy/TandC";
import up from "../src/Assets/move-up.svg";
import PricingPage from "./Pages/Pricing/PricingPage";
import PricingDetailPage from "./Pages/Pricing/PricingDetailPage";
import PaymentThirdParty from "./Pages/RedirectPage/RedirectPage";

function App() {
  return (
    <div className="font-Poppins scroll">
      {/* <Navbar /> */}
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/investorscorner" element={<Funding />} />
            <Route
              path="/investorscorner/:product"
              element={<InvestorCorner />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/clients" element={<Client />} />
            <Route path="/researchAndDevelopment" element={<RandD />} />
            <Route path="/privacypolicy" element={<Privacy />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/tandc" element={<TandC />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/pricing/details" element={<PricingDetailPage />} />
            <Route path="/thirdpartyPayment" element={<PaymentThirdParty/>}/>
          </Routes>
        </BrowserRouter>
        <img
          src={up}
          alt=""
          className="fixed bottom-3 right-3 hover:cursor-pointer w-[50px] h-[50px]"
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
        />
      </div>
    </div>
  );
}

export default App;
