import React from 'react'
import Navbar from '../../Components/Navbar/Navbar.js'
import './LandingPage.css'
import img1 from '../../Assets/image1.jpg'
// import img4 from '../../Assets/image4.png'
// import img5 from '../../Assets/image5.png'
// import img6 from '../../Assets/image6.png'
import img7 from '../../Assets/image7.png'
import arrow from '../../Assets/rightArrow.png'
//import productData from '../../json/products.json'
import Footer from '../../Components/Footer/Footer.js'
import { useState, useEffect } from 'react'
import axios from 'axios';


const LandingPage = () => {
  const [productData, setProductData] = useState({});
  useEffect( () => {
      axios
      .get('json/products.json')
      .then(response => setProductData(response.data));

      window.scrollTo(0, 0)

  },[]);

  return (
    <div className='h-[100vh] w-[100%]'>
      <Navbar />
      <div className='max-w-[1440px] w-[100%] mx-auto'>
        <section className='px-[3%] pt-[20px]'>
          <p className='heading'>Your reliable IT partner<br />supporting you every step of the way.</p>
          <p className='subheading2 md:mt-[20px] mt-[16px] max-w-[959px]'>We listen closely to your needs and leverage our extensive IT experience to provide informed recommendations, ensuring your vision becomes a reality.</p>
          {/* <p className='clicktomore hover:cursor-pointer md:mt-[30px] mt-[16px]'>Learn about our mission</p> */}
        </section>
      </div>
      <img src={img1} alt="" className='mt-[30px] md:mt-[60px] w-[100%] max-w-[1440px] mx-auto min-h-[470px] object-cover' />
      <div className='max-w-[1440px] w-[100%] mx-auto'>
        <section className='px-[3%] md:pt-[120px] pt-[60px]'>
          <p className='textstyle1 pb-[16px]'>What we do and how?</p>
          <p className='subheading'>Bringing people closer every day. We take care business and people around the world.</p>
          <p className='heading2 md:pt-[60px] pt-[30px]'>Featured products</p>
        </section>
        {/* Featured Products */}
        <section className='px-[3%] md:pt-[60px] pt-[30px]'>
          {/* <div className='flex'>
            <div className='pr-[30px] products hover:cursor-pointer'>All</div>
            <div className='pr-[30px] products hover:cursor-pointer'>Website</div>
            <div className='pr-[30px] products hover:cursor-pointer'>Mobile App</div>
            <div className='pr-[30px] products hover:cursor-pointer'>Upcoming</div>
          </div>
          <div className='separator mt-[20px]'></div> */}

          <div className='grid grid-cols-2 lg:gap-[40px] md:gap-[20px] gap-[8px]'>
            {productData.website?.map(obj => {
              return (
                <div className=' md:mt-[60px] mt-[30px] '>
                  <img src={obj?.img} alt="" className='' />
                  <p className='subheading2 md:mt-[30px] mt-[8px]' style={{ fontWeight: '600' }}
                  >{obj?.name}</p>
                  <p className='textstyle1 md:mt-[20px] mt-[6px]' style={{ fontWeight: '400' }}>{obj?.description}</p>
                  <p className='mt-[6px] md:mt-[8px] text-blue-600 underline hover:cursor-pointer text-[12px] md:text-[14px]'
                    onClick={() => window.open(obj?.url)}
                  >{obj?.name}
                  {/* <span><img src={obj?.linkLogo} alt=""/></span> */}
                  </p>
                </div>
              )
            })}
            {/* <div className=' md:mt-[60px] mt-[30px]'>
              <img src={img3} alt="" className='' />
              <p className='subheading2 md:mt-[30px] mt-[8px] hover:cursor-pointer' style={{ fontWeight: '600' }}
                onClick={() => window.open('https://www.goviral.world', '_self')}
              >GoViral.world</p>
              <p className='textstyle1 md:mt-[20px] mt-[6px]' style={{ fontWeight: '400' }}>A product description is the marketing copy that explains what a product is and why it's worth purchasing. </p>
            </div> */}
            {/* <div className='w-[600px] mt-[60px]'>
            <img src={img4} alt="" className='h-[640px] w-[600px]' />
            <p className='subheading2 mt-[30px]' style={{ fontWeight: '600' }}>QueueEasy.com</p>
            <p className='textstyle1 mt-[20px]' style={{ fontWeight: '400' }}>A product description is the marketing copy that explains what a product is and why it's worth purchasing. </p>
          </div>
          <div className='w-[600px] mt-[60px]'>
            <img src={img5} alt="" className='h-[640px] w-[600px]' />
            <p className='subheading2 mt-[30px]' style={{ fontWeight: '600' }}>Shootmates</p>
            <p className='textstyle1 mt-[20px]' style={{ fontWeight: '400' }}>A product description is the marketing copy that explains what a product is and why it's worth purchasing. </p>
          </div>
          <div className='w-[600px] mt-[60px]'>
            <img src={img6} alt="" className='h-[640px] w-[600px]' />
            <p className='subheading2 mt-[30px]' style={{ fontWeight: '600' }}>Memshots</p>
            <p className='textstyle1 mt-[20px]' style={{ fontWeight: '400' }}>A product description is the marketing copy that explains what a product is and why it's worth purchasing. </p>
          </div> */}
          </div>
        </section>

        <section className='px-[3%] pb-[130px]'>
          <div className='w-[100%] flex flex-col items-center md:mt-[220px] mt-[120px]'>
            <p className='heading2 text-center'>Investor’s corner</p>
            <div className='border1 md:mt-[60px] mt-[30px] flex justify-between hover:cursor-pointer select-none'
              onClick={() => window.open(`https://wa.me/+919567183183`)}
            >
              <p className='font-[600] leading-[26px] text-[17px] md:text-[20px]'> Get in touch</p>
              <img src={arrow} alt="" className='h-[40px] w-[40px]' />
            </div>
          </div>
          <div className='w-[100%] flex flex-wrap md:mt-[120px] mt-[60px] justify-around'>
            <div className='w-[600px] max-h-[516px]'>
              <p className='heading2' style={{ fontWeight: '500px' }}>Feeling good about a project? Let’s talk.</p>
              <p className='subheading3 md:mt-[30px] mt-[20px] inter'>The future of business takes place at the creative intersections of commerce, science, technology, society and sustainability. Business success is allied with societal progress. </p>
            </div>
            <img src={img7} className='w-[600px] max-h-[516px] object-contain mt-[30px] md:mt-[0px]' />
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default LandingPage
