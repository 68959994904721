import React from 'react'
import styles from '../../Pages/About/About.module.css'
const AboutUs = ({ data }) => {
  return (
    <>
      <div className='w-[100%] bg-[#161616] pb-[100px] pt-[60px] md:pt-[120px]'>
        <div className='w-[100%] px-[3%] max-w-[1440px] mx-auto text-white'>

          <div className='flex flex-wrap gap-[20px]'>
            <div className=''>
              <p className={`text-[30px] font-[600] leading-[120%] mt-[8px] ${styles.hideLarge} text-white mb-[30px]`}>{data.founderHeading}</p>
              <img src={data.founder} alt="" className='md:max-w-[400px] min-w-[360px] w-[100%] max-h-[500px]' />
              <div className='text-white'>
                <p className='text-[17px] md:text-[36px] font-[600] leading-[25.5px] md:leading-[44px] mt-[8px] md:mt-[20px]'>{data.founderName} </p>
                <p className='text-[px] md:text-[] font-[400] leading-[px] md:leading-[px] mt-[4px] md:mt-[8px]'>{data.founderPosition}</p>
              </div>
            </div>
            <div className='text-white max-w-[750px]'>
              <p className={`text-[64px] font-[600] leading-[120%] mt-[8px] ${styles.hideSmall}`}>{data.founderHeading}</p>
              <div className='md:text-[18px] text-[14px] font-[400] leading-[24px] md:mt-[30px] mt-[20px] opacity-60 inter' dangerouslySetInnerHTML={{ __html: data.founderDescription }} />
            </div>
          </div>

          <div>
            <div className='border-white border-[1px] px-[10px] py-[8px] rounded-full md:w-[169px] w-[125px] font-[300] text-[14px] md:text-[20px] leading-[26px] mt-[60px] md:mt-[120px]'>Meet the team</div>
            <p className='font-[600] text-[17px] md:text-[36px] leading-[26px] md:leading-[44px] mt-[15px] md:mt-[30px] max-w-[662px]'>{data.teamHeading}</p>
            <div className='grid grid-cols-2 md:grid-cols-4 mt-[20px] md:mt-[30px] gap-[8px] md:gap-[20px]'>
              {data.team?.map(obj => {
                return (
                  <div className='mt-[12px] md:mt-[10px]'>
                    <img src={obj.img} alt="" />
                    <p className='font-[500] text-[16px] md:text-[22px] leading-[24px] md:leading-[33px] mt-[8px] md:mt-[16px]'>{obj.name}</p>
                    <p className='font-[400] text-[12px] md:text-[16px] leading-[18px] md:leading-[24px] mt-[2px] md:mt-[4px] opacity-60'>{obj.role}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
