import React from "react";
import { useNavigate } from "react-router-dom";

import "./Navbar.css";

import logo from "../../Assets/egLabsLogo.png";
import close from "../../Assets/rightArrow.png";
import hamburger from "../../Assets/hamburger.svg";

const Navbar = () => {
  let page = window.location.href.split("/")[3];
  const navigate = useNavigate();
  const highlight = `w-auto h-[40px] bg-black rounded-full text-white px-[20px] py-[8px]`;
  const openHam = () => {
    document.getElementById("hamWindow").style.width = "300px";
    document.getElementById("hamWindow").style.display = "block";
  };
  const closeHam = () => {
    document.getElementById("hamWindow").style.width = "0px";
    document.getElementById("hamWindow").style.display = "none";
  };
  return (
    <div className="h-[15%] max-w-[1440px] w-[100%] px-[3%] flex justify-between items-center py-[40px] mx-auto">
      <img
        src={logo}
        alt="eg labs"
        className="h-[45px] w-[72px] hover:cursor-pointer"
        onClick={() => navigate("/")}
      />
      <div className="text-[16px] font-extralight nav-contents">
        <span
          className={`${
            page == "researchAndDevelopment" ? highlight : ""
          } mr-[40px] hover:cursor-pointer`}
          onClick={() => navigate("/researchAndDevelopment")}
        >
          Research & Development
        </span>
        <span
          className={`${
            page == "clients" ? highlight : ""
          } mr-[40px] hover:cursor-pointer`}
          onClick={() => navigate("/clients")}
        >
          Clients
        </span>
        <span
          className={`${
            page == "about" ? highlight : ""
          } mr-[40px] hover:cursor-pointer`}
          onClick={() => navigate("/about")}
        >
          About
        </span>
        <span
          className={`${
            page == "investorscorner" ? highlight : ""
          } mr-[40px] hover:cursor-pointer`}
          onClick={() => navigate("/investorscorner")}
        >
          Investor's Corner
        </span>
        <span
          className={`${
            page == "pricing" ? highlight : ""
          } mr-[40px] hover:cursor-pointer`}
          onClick={() => navigate("/pricing")}
        >
          Buy Products
        </span>
      </div>
      <img
        src={hamburger}
        alt=""
        className="w-[30px] h-[30px] hamburger hover:cursor-pointer"
        onClick={openHam}
      />
      <div
        className="bg-white h-auto w-[400px] absolute right-0 top-6 drop-shadow-xl hidden z-[1] "
        id="hamWindow"
      >
        <div className="px-[16px] py-[10px]">
          <div
            onClick={closeHam}
            className="hover:cursor-pointer flex items-center justify-center bg-black rounded-full w-[24px]"
          >
            <img src={close} alt="" className="h-[24px]" />
          </div>
          <div className="text-[16px] font-extralight flex flex-col mt-[10px] gap-[8px]">
            <span
              className={`${
                page == "randd" ? highlight : ""
              } hover:cursor-pointer hover:text-blue-600 select-none`}
              onClick={() => navigate("/researchAndDevelopment")}
            >
              Research & Development
            </span>
            <span
              className={`${
                page == "clients" ? highlight : ""
              } hover:cursor-pointer hover:text-blue-600 select-none`}
              onClick={() => navigate("/clients")}
            >
              Clients
            </span>
            <span
              className={`${
                page == "about" ? highlight : ""
              } hover:cursor-pointer hover:text-blue-600 select-none`}
              onClick={() => navigate("/about")}
            >
              About
            </span>
            <span
              className={`${
                page == "investorscorner" ? highlight : ""
              } hover:cursor-pointer hover:bg-blue-600 select-none`}
              onClick={() => navigate("/investorscorner")}
            >
              Investor's Corner
            </span>
            <span
              className={`${
                page == "investorscorner" ? highlight : ""
              } hover:cursor-pointer hover:bg-blue-600 select-none`}
              onClick={() => navigate("/pricing")}
            >
              Buy Products
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
